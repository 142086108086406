import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Nav,
  Navbar,
  Container,
  Col,
  Button,
  InputGroup,
  Form,
} from "react-bootstrap";
import {
  faCheck,
  faBookmark,
  faUser,
  faDoorOpen,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ContextApiContext } from "../context/ContextApi";
import { capitalizeFirstLetter } from "../common/Common";
import LoginModal from "./Components/LoginModal"; // Import your LoginModal component
import Row from "react-bootstrap/Row";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { is_loggedin, is_user } from "../common/Common";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export default function Nav_bar_area(props) {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false); // State for login modal
  const [isAuthenticated, setIsAuthenticated] = useState(false); // For demonstration, assuming initially not authenticated
  const { contextState, updateContextState } = useContext(ContextApiContext);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      console.log("User data found:", userData);
      setIsAuthenticated(true);
    } else {
      console.log("No user data found.");
    }
  }, []);

  const openLoginModal = () => {
    updateContextState(true, "show_login_modal");
  };

  const logout = () => {
    handleLogout();
    navigateToPath("/");
  };

  const navigateToPath = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    console.log("Logging out...");
    localStorage.removeItem("userData");
    updateContextState({}, "logout_user");
    setIsAuthenticated(false);
  };

  const [searchText, setSearchText] = useState("");
  const [lookingfor, setLookingfor] = useState("job");

  const googleTranslateElementInit = () => {
    try {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false,
          },
          "google_translate_element"
        );
      } else {
        console.error("Google Translate API not available");
      }
    } catch (e) {
      console.error("Google Translate API not available");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      googleTranslateElementInit(); // Check if this function is called
    }, 3000);
  }, []);

  const setLookingforHandler = (keyvalue) => {
    setLookingfor(keyvalue);
    console.log("lookingfor", keyvalue);
  };

  const setSearchTextHandler = (text) => {
    setSearchText(text);
  };

  const setSearchHandler = () => {
    updateContextState(
      {
        reload: true,
        lookingfor: lookingfor,
        text: searchText,
      },
      "search"
    );
    navigateToPath("/categorydetaillisting");
  };

  const radios = [
    { name: "Job", value: "job" },
    { name: "Help", value: "help" },
  ];

  return (
    <>
      <Navbar
        expand="lg"
        id="scrl_up"
        className="bg-body-tertiary"
        variant="dark"
      >
        <Container fluid className="nav_back">
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Brand className="brand_tx" href="#">
            <div className="logo_are">
              <img
                className="nav_im"
                src="/images/slicing/logo-1_promi.png"
                onClick={() => navigateToPath("/")}
              />
            </div>
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <div className="logo_menu_box">
                  <img
                    className="collapse_logo"
                    src="/images/slicing/Help101-Web.png"
                  />
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 ">
                <Row className="top_spc">
                  <Col xs={12} md={5}></Col>

                  <Col xs={12} md={3} className="serch_area">
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        className="srch_inp"
                        onChange={(e) => setSearchTextHandler(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            setSearchHandler();
                          }
                        }}
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={setSearchHandler}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>

                  <Col xs={12} md={2} className="radio-button-group">
                    <ButtonGroup toggle className="d-flex">
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant="outline-"
                          className="dp_btn-side mg_btm toggle-btn"
                          name="radio"
                          value={radio.value}
                          checked={lookingfor === radio.value}
                          onChange={(e) =>
                            setLookingforHandler(e.currentTarget.value)
                          }
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>

                  {!is_loggedin() ? (
                    <Col xs={12} md={1}>
                      <Button
                        className="dp_btn-side mg_btm"
                        onClick={openLoginModal}
                      >
                        LogIn
                      </Button>
                    </Col>
                  ) : null}

                  {is_loggedin() ? (
                    <Col xs={12} md={1}>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dp_btn-basic mg_btm dp_btn_icn"
                          variant="success"
                          id="dropdown-basic"
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigateToPath("/mybookmarks")}
                          >
                            My Bookmarks
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigateToPath("/myjobs")}
                          >
                            My Jobs
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : null}

                  {!is_loggedin() ? (
                    <Col xs={12} md={1}>
                      <Button
                        className="dp_btn-side mg_btm"
                        onClick={() => navigateToPath("/register")}
                      >
                        Register
                      </Button>
                    </Col>
                  ) : null}

                  {is_loggedin() ? (
                    <Col xs={12} md={1}>
                      <Button className="dp_btn-side mg_btm" onClick={logout}>
                        LogOut
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <LoginModal
        show={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </>
  );
}
