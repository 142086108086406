import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../styles/register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Common, { googleTranslate, SendRequest } from "../common/Common";
import Nav_bar_area from "./NavBar";
import StepWizard from "react-step-wizard";
import Dropdown from "react-bootstrap/Dropdown";

export default function Register() {
  const navigate = useNavigate();

  const navigateToPath = (path) => {
    navigate(path);
  };
  const [show, setShow] = useState(false);
  const { contextState, updateContextState } = useContext(ContextApiContext);
  const [companyName, setCompanyName] = useState("");
  const [numberOfEmployee, setNumberOfEmployee] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isManager, setIsManager] = useState("");
  const [isReportingChain, setIsReportingChain] = useState("");
  const [heardUS, setHeardUS] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const add_company = async () => {
    try {
      console.log("companyName", companyName);
      console.log("numberOfEmployee", numberOfEmployee);
      console.log("firstName", firstName);
      console.log("lastName", lastName);
      console.log("isManager", isManager);
      console.log("isReportingChain", isReportingChain);
      console.log("heardUS", heardUS);
      console.log("phoneNumber", phoneNumber);
      console.log("website", website);

      var formData = new FormData();
      formData.append("company_name", companyName);
      formData.append("number_of_employees", numberOfEmployee);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("is_hiring_manager", isManager);
      formData.append("hired_candidates", isReportingChain);
      formData.append("how_you_heard_about_us", heardUS);
      formData.append("phone_number", phoneNumber);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("website", website);

      let cs = contextState;

      const res = await SendRequest("POST", Constant.add_company, formData);

      console.log("rrrrrrrrrrr", res);
      if (res.status) {
        console.log("sign ", res.response);
        let res_obj = res.response;
        let user_obj = res_obj.user;
        // user_obj.rememberme = rememberme;
        // user_obj.is_loggedin = true;
        updateContextState(user_obj, "update_user");

        navigateToPath("/");
      } else {
        console.log("resss", res);
        // if (res.error.custom_code == 403) {
        //   console.log("403 error");
        //   updateContextState(true, "show_login_modal");
        //   updateContextState("Please Login and try again", "error_msg");

        //   // navigateToPath(-1);
        // }
        updateContextState(res.error?.message[0], "error_msg");
      }
    } catch (error) {
      console.error("Error updating agent :", error);
    }
  };

  return (
    <div>
      <Nav_bar_area className="colooo" />
      <section className="bg_blu_bk">
        <Container>
          <Row>
            {/* <div className="login_head"> */}
            {/* <Col md={6}>
              <div className="backicon">
                <Button
                  className="bcbtn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </div>{" "}
            </Col> */}
            <Col>
              <h3 className="top_heading_page">Register</h3>
            </Col>

            {/* </div> */}
          </Row>
        </Container>
        <div className="login_back">
          <Container>
            <div className="whl_login">
              <Row>
                <div>
                  <div>
                    <h5>
                      You haven't posted a job before, so you'll need to create
                      an employer account.
                    </h5>

                    <Row>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Your company's name *{" "}
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            onChange={(e) => setCompanyName(e.target.value)}
                            id="basic-url"
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Your company's number of employees
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) =>
                              setNumberOfEmployee(e.target.value)
                            }
                            aria-describedby="basic-addon3"
                            type="number"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">First Name</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setFirstName(e.target.value)}
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">Last Name</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setLastName(e.target.value)}
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12}>
                        <Form.Label htmlFor="basic-url">
                          Are you a hiring manager?
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Select
                            id="basic-url"
                            onChange={(e) => setIsManager(e.target.value)}
                            aria-describedby="basic-addon3"
                          >
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </Form.Select>
                        </InputGroup>
                      </Col>

                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          The hired candidate(s) will work in your reporting
                          chain.
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) =>
                              setIsReportingChain(e.target.value)
                            }
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          How you heard about us
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setHeardUS(e.target.value)}
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Phone Number{" "}
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            aria-describedby="basic-addon3"
                            type="number"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Email
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setEmail(e.target.value)}
                            aria-describedby="basic-addon3"
                            value={email}

                          />
                        </InputGroup>
                      </Col>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Password
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setPassword(e.target.value)}
                            aria-describedby="basic-addon3"
                            value={password}
                            type="password"

                          />
                        </InputGroup>
                      </Col>
                     
                    </Row>
                    <Row>
                      <Col md={6} xs={12}>
                        {" "}
                        <Form.Label htmlFor="basic-url">
                          Website
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => setWebsite(e.target.value)}
                            aria-describedby="basic-addon3"
                            value={website}

                          />
                        </InputGroup>
                      </Col>
                      
                     
                    </Row>

                    <Row>
                      <div className="btn_area_sbmt">
                        <p>
                          <button
                            className="btm_btn"
                            onClick={() => add_company()}
                          >
                            Submit
                          </button>
                        </p>
                      </div>{" "}
                    </Row>
                  </div>
                </div>
              </Row>
              <Row>
                <Col></Col>
                <Col></Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </div>
  );
}
 